<template>
	<layouts-main>
		<home-location v-if="!isDefined" />
		<section v-if="isDefined">
			<store-stories-list />
			<user-location-button class="d-block d-md-none mt-6" />
			<assistant-banner />
		</section>
		<div class="pt-8 pb-10">
			<stores-list v-if="isDefined" />
		</div>
	</layouts-main>
</template>

<script>
	import { computed, onMounted } from 'vue';
	import store from '@/store';

	export default {
		setup() {
			const isDefined = computed(() => store.getters['location/isDefined']);
			const complete = computed(() => store.getters['location/complete']);
			const address = computed(() => store.getters['location/address']);

			onMounted(() => store.dispatch('marketing/setLanding', 0));

			return { isDefined, complete, address };
		}
	}
</script>